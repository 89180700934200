import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type TopBarProps = {
    toggleOpen: () => void
    close: () => void
    open: boolean
}

const TopBar: FC<TopBarProps> = (props: TopBarProps) => {
    return <div className={props.open ? "topnav responsive" : "topnav"}>
        <Link to="/" id="logoName" className="active">nagitare.pl</Link>
        <Link to="/catalogue" className="regular" onClick={props.close}>Katalog </Link>
        <Link to="/advanced" className="regular" onClick={props.close}>Wyszukiwanie zaawansowane</Link>
        <Link to="/contact" className="regular" onClick={props.close}>Zaproponuj utwór</Link>
        <Link to="/about" className="regular" onClick={props.close}>O projekcie</Link>
        <a href="javascript:void(0);" className="icon regular" onClick={props.toggleOpen}>
            <div id="navigation">
                <div className="menuBar" id="top"></div>
                <div className="menuBar" id="middle"></div>
                <div className="menuBar" id="bottom"></div>
            </div>
        </a>
    </div >
}

export default TopBar