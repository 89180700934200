export interface CreateComposerDTO {
    surname: string,
    givenNames: string,
    yearBorn: number | null,
    yearDeceased: number | null
}

export interface CreateInstrumentDTO {
    name: string,
    isCountable: boolean
}

export interface PageResponseDTO<T> {
    totalPages: number
    result: T[]
}

export class InstrumentDTO implements Displayable {
    id: number
    name: string
    isCountable: boolean
    constructor(id: number = 0, name: string = "", isCountable: boolean) {
        this.id = id
        this.name = name
        this.isCountable = isCountable
    }
    displayableName() {
        return this.name
    }
}

export interface InstrumentationDTO {
    instrument: InstrumentDTO
    amount: number | undefined
    position: number
}

export class ComposerDTO implements Displayable {
    surname: string
    givenNames: string
    id: number
    yearBorn: number | null
    yearDeceased: number | null
    constructor(
        surname: string  = "",
        givenNames: string  = "",
        id: number = 0,
        yearBorn: number | null = null,
        yearDeceased: number | null = null) {
        this.surname = surname
        this.givenNames = givenNames
        this.id = id
        this.yearBorn = yearBorn
        this.yearDeceased = yearDeceased
    }
    displayableName() {
        return this.surname + (this.givenNames ? ", " + this.givenNames : "")
    }
}

export interface CreateOrUpdateCompositionDTO {
    composerId: number | undefined
    title: string | undefined
    createdYears: number | undefined
    lyricsAuthor: string | undefined
    dedication: string
    parts: string
    premiere: string
    editionInfo: string
    compositionCompetitions: string
    recordings: string
    literature: string
    manuscript: string
    notes: string
    duration: string
    instrumentation: InstrumentationDTO[]
    isReviewed: boolean
}

export class CompositionDTO implements Displayable {
    title: string
    subtitle: string
    id: number
    composer?: ComposerDTO
    createdYears: string
    lyricsAuthor: string
    dedication: string
    parts: string
    premiere: string
    editionInfo: string
    compositionCompetitions: string
    recordings: string
    literature: string
    manuscript: string
    notes: string
    duration: string
    instrumentation: InstrumentationDTO[]
    isReviewed: boolean
    matchedFields: string[]

    constructor(
        title: string = "",
        subtitle: string = "",
        id: number = 0,
        composer: ComposerDTO | undefined = undefined,
        createdYears: string = "",
        lyricsAuthor: string = "",
        dedication: string = "",
        parts: string = "",
        premiere: string = "",
        editionInfo: string = "",
        compositionCompetitions: string = "",
        recordings: string = "",
        literature: string = "",
        manuscript: string = "",
        notes: string = "",
        duration: string = "",
        instrumentation: InstrumentationDTO[] = [],
        isReviewed: boolean = true,
        matchedFields: string[] = []
    ) {
        this.title = title
        this.subtitle = subtitle
        this.id = id
        this.composer = composer
        this.createdYears = createdYears
        this.lyricsAuthor = lyricsAuthor
        this.dedication = dedication
        this.parts = parts
        this.premiere = premiere
        this.editionInfo = editionInfo
        this.compositionCompetitions = compositionCompetitions
        this.recordings = recordings
        this.literature = literature
        this.manuscript = manuscript
        this.notes = notes
        this.duration = duration
        this.instrumentation = instrumentation
        this.isReviewed = isReviewed
        this.matchedFields = matchedFields
    }

    displayableName(): string {
        return this.title
    }

}

export interface Displayable {
    displayableName(): string;
}

export const compareByPosition = (a: InstrumentationDTO, b: InstrumentationDTO): number  => {
    if (a.position === b.position) {
        return 0;
    }
    else if (a.position < b.position) {
        return -1;
    }
    return 1;

};

export interface EmailDTO {
    returnAddress: string
    body: string
}

export interface SearchRequestDTO {
    instrumentation: InstrumentationDTO[]
}