import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { getComposerPage } from './api';
import { ComposerDTO } from './dto/DtoTypes';
import { Link, Redirect, useLocation, useRouteMatch } from 'react-router-dom';

enum SortDirection {
    Ascending = "ascend",
    Descending = "descend"
}

enum SortField {
    Surname = "surname",
    GivenNames = "givenNames",
    YearBorn = "yearBorn",
    YearDeceased = "yearDeceased"
}

type SortOrder = {
    direction: SortDirection
    field: SortField
}

type SortOption = {
    sortOrder: SortOrder,
    displayableName: string
}

type Pagination = {
    totalPages: number
    pageSize: number
    currentPage: number
}

const sortOptions: { [id: string]: SortOption } = {
    "surnameAsc": {
        sortOrder: {
            direction: SortDirection.Ascending,
            field: SortField.Surname
        },
        displayableName: "Sortuj po nazwisku, rosnąco"
    },
    "surnameDesc": {
        sortOrder: {
            direction: SortDirection.Descending,
            field: SortField.Surname
        },
        displayableName: "Sortuj po nazwisku, malejąco"
    },
    "givenNamesAsc": {
        sortOrder: {
            direction: SortDirection.Ascending,
            field: SortField.GivenNames
        },
        displayableName: "Sortuj po imionach, rosnąco"
    },
    "givenNamesDesc": {
        sortOrder: {
            direction: SortDirection.Descending,
            field: SortField.GivenNames
        },
        displayableName: "Sortuj po imionach, malejąco"
    },
    "yearBornAsc": {
        sortOrder: {
            direction: SortDirection.Ascending,
            field: SortField.YearBorn
        },
        displayableName: "Sortuj po roku urodzenia, rosnąco"
    },
    "yearBornDesc": {
        sortOrder: {
            direction: SortDirection.Descending,
            field: SortField.YearBorn
        },
        displayableName: "Sortuj po roku urodzenia, malejąco"
    },
    "yearDeceasedAsc": {
        sortOrder: {
            direction: SortDirection.Ascending,
            field: SortField.YearDeceased
        },
        displayableName: "Sortuj po roku śmierci, rosnąco"
    },
    "yearDeceasedDesc": {
        sortOrder: {
            direction: SortDirection.Descending,
            field: SortField.YearDeceased
        },
        displayableName: "Sortuj po roku śmierci, malejąco"
    },
}

const Catalogue: React.FC = () => {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const sorting = query.get("sorting")
    const pageSize = query.get("pageSize")
    const currentPage = query.get("currentPage")

    const [loading, setLoading] = useState(true)
    const [changed, setChanged] = useState(false)
    const [composers, setComposers] = useState<ComposerDTO[]>([])
    const [currentPagination, setPagination] = useState<Pagination>(
        {
            currentPage: parseInt(currentPage ? currentPage : "1"),
            pageSize: parseInt(pageSize ? pageSize : "10"),
            totalPages: 0
        }
    )
    const [currentSorting, setSorting] = useState<string>(sorting ? sorting : "surnameAsc")

    const fetchComposersPage = (pagination: Pagination, sorting: string) => {
        setLoading(true)
        getComposerPage(pagination.currentPage, pagination.pageSize, sortOptions[sorting].sortOrder.field, sortOptions[sorting].sortOrder.direction)
            .then(res => {
                setComposers([...res.result])
                setPagination({
                    totalPages: res.totalPages,
                    currentPage: pagination.currentPage,
                    pageSize: pagination.pageSize
                })
            })
            .catch((e) => { console.log(e) })
            .finally(() => setLoading(false))
    }

    const onSortingChange = (sorting: string) => {
        setSorting(sorting)
        setChanged(true)
    }

    const onPageSizeChange = (newPageSize: number) => {
        setPagination({ ...currentPagination, pageSize: newPageSize, currentPage: 1 })
        setChanged(true)
    }

    useEffect(() => {
        fetchComposersPage(currentPagination, currentSorting)
    }, [])
    const match = useRouteMatch()
    if (changed) {
        return <Redirect push to={getCurrentUrl(match.path, currentSorting, currentPagination.currentPage, currentPagination.pageSize)} />
    }


    const moreThanFourPages = currentPagination.totalPages > 4;
    const displayFirst = currentPagination.currentPage > 3 && moreThanFourPages;
    const displayFfDown = currentPagination.currentPage > 4 && moreThanFourPages;
    const displayLast = currentPagination.currentPage < currentPagination.totalPages - 2 && moreThanFourPages;
    const displayFfUp = currentPagination.currentPage < currentPagination.totalPages - 3 && moreThanFourPages;

    const optionalLinkToFirstPage = displayFirst && <Link
        to={getCurrentUrl(match.path, currentSorting, 1, currentPagination.pageSize)} 
        className="solidPaginationLink">
        1
        </Link>;
    const optionalFfDown = displayFfDown
        && <Link 
        to={getCurrentUrl(match.path, currentSorting, currentPagination.currentPage - 3, currentPagination.pageSize)}
        id="ffDownPaginationLink">
            <span>&middot;&middot;&middot;</span>
            </Link>


    const lo = !displayFirst ? 1 : !displayLast ? currentPagination.totalPages - 5 : currentPagination.currentPage - 2
    const hi = !displayLast ? currentPagination.totalPages : !displayFirst ? 5 : currentPagination.currentPage + 2
    const currentAndNeighbours = [...Array(currentPagination.totalPages)]
        .map((_, i) => i + 1) // pages are 1-based
        .filter(i => {
            return i >= lo && i <= hi
        })
        .map(i =>
            <Link key={i}
                to={getCurrentUrl(match.path, currentSorting, i, currentPagination.pageSize)}
                className={i === currentPagination.currentPage ? "activePaginationLink" : "solidPaginationLink"} >
                {i}
            </Link>)
    const optinalLinkToLastPage = displayLast
        && <Link
            to={getCurrentUrl(match.path, currentSorting, currentPagination.totalPages, currentPagination.pageSize)}
            className="solidPaginationLink">
            {currentPagination.totalPages}
        </Link>;
    const optionalFfUp = displayFfUp
        && <Link
            to={getCurrentUrl(match.path, currentSorting, currentPagination.currentPage + 3, currentPagination.pageSize)}
            id="ffUpPaginationLink">
            <span>&middot;&middot;&middot;</span>
        </Link>

    const paginationElements = [optionalLinkToFirstPage, optionalFfDown, ...currentAndNeighbours, optionalFfUp, optinalLinkToLastPage]

    const sizePageText = " na stronę";
    return <div id="catalogue">
        <h1>Katalog kompozytorów</h1>
        <div id="sorting">
            <select id="sortingSelect"
                style = {
                    {
                        marginTop: "15px"
                    }
                }
                value={currentSorting}
                onChange={e => onSortingChange(e.target.value)}>
                {Object.entries(sortOptions).map(o => {
                    let [key, option] = o
                    return <option value={key} key={option.displayableName}>{option.displayableName}</option>
                })}
            </select>
        </div>
        {/* TODO extract pagination to separate component */}
        <div id="pagination">
            {loading ? <Spin size="small" /> : paginationElements}
            <select id="pageSizeSelect"
                value={currentPagination.pageSize + sizePageText}
                onChange={e => onPageSizeChange(parseInt(e.target.value.replace(sizePageText, "").trim()))}>
                <option>{"10" + sizePageText}</option>
                <option>{"20" + sizePageText}</option>
                <option>{"50" + sizePageText}</option>
                <option>{"100" + sizePageText}</option>
            </select>
        </div>
        <table id="catalogueTable">
            {/* <colgroup>
                <col className="surnameCol"/>
                <col />
                <col />
                <col />
            </colgroup> */}
            <thead>
                <tr>
                    <th className="columnHeader" id="leftmost">Nazwisko</th>
                    <th className="columnHeader">Imiona</th>
                    <th className="columnHeader">Rok urodzenia</th>
                    <th className="columnHeader" id="rightmost">Rok śmierci</th>
                </tr>
            </thead>
            {loading
                ? <Spin size="small" />
                : <tbody>
                    {composers.map(c => <tr className="composerRow" key={c.id}>
                        <td className="surname"><Link to={`/catalogue/composer/${c.id}`}>{c.surname}</Link></td>
                        <td className="givenNames">{c.givenNames}</td>
                        <td className="yearBorn">{c.yearBorn}</td>
                        <td className="yearDeceased">{c.yearDeceased}</td>
                    </tr>)}
                </tbody>}

        </table>
        <div id="pagination">
            {loading ? <Spin size="small" /> : paginationElements}
            <select id="pageSizeSelect"
                value={currentPagination.pageSize + sizePageText}
                onChange={e => onPageSizeChange(parseInt(e.target.value.replace(sizePageText, "").trim()))}>
                <option>{"10" + sizePageText}</option>
                <option>{"20" + sizePageText}</option>
                <option>{"50" + sizePageText}</option>
                <option>{"100" + sizePageText}</option>
            </select>
        </div>
    </div>
}

export default Catalogue;

function getCurrentUrl(path: string, currentSorting: string, currentPage: number, pageSize: number) {
    return `${path}?sorting=${currentSorting}&currentPage=${currentPage}&pageSize=${pageSize}`;
}
