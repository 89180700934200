import React, { useState } from 'react';
import { Row, Col, Form, Button, Input, Modal, Checkbox } from "antd";
import SearchWithNotFoundFunction from './SearchWithNotFoundFunction';
import { InstrumentDTO, InstrumentationDTO, CreateInstrumentDTO } from './dto/DtoTypes';
import { addInstrument } from './api';
import { Store } from 'antd/lib/form/interface';

type InstrumentationItemFieldProps = {
    value?: InstrumentationDTO;
    onChange?: (value: InstrumentationDTO) => void;
    fetchInstruments: (value: string, callback: (data: InstrumentDTO[]) => void) => void
    onDeleteRow: () => void
    admin: boolean
}

const InstrumentationItemField: React.FC<InstrumentationItemFieldProps> = (props: InstrumentationItemFieldProps) => {
    const [isInstrumentButtonVisible, setInstrumentButtonVisible] = useState<boolean>(false)
    const [isInstrumentModalVisible, setInstrumentModalVisible] = useState<boolean>(false)
    const [currentInstrumentation, setCurrentInstrumentation] = useState<InstrumentationDTO>(props.value!)
    const handleInstrumentAdd = (values: Store) => {
        addInstrument({ name: values.name, isCountable: values.isCountable } as CreateInstrumentDTO)
            .finally(() => setInstrumentModalVisible(false))
    }

    const handleModalCancel = () => {
        setInstrumentModalVisible(false)
    }

    const showModal = () => {
        setInstrumentModalVisible(true)
        setInstrumentButtonVisible(false)
    }


    const [form] = Form.useForm();

    return <div className="instrItemField">
        <div className="instrItemRow">
            <div className="instrItemInstrument">
                <SearchWithNotFoundFunction<InstrumentDTO>
                    notFoundFunction={props.admin
                        ? (_) => {
                            setInstrumentButtonVisible(true);
                        }
                        : (_) => { } // do nothing if viewing as regular user
                    }
                    initialValues={[]}
                    fetchingFuntion={props.fetchInstruments}
                    onChange={value => {
                        if (props.admin) {
                            setInstrumentButtonVisible(false)
                        }
                        let instrumentationItem = currentInstrumentation
                        instrumentationItem.instrument = value!
                        // if instrument changed from countable to uncountable we want to reset value
                        if (!value?.isCountable) {
                            instrumentationItem.amount = undefined
                        }
                        // if adding new countable we want to insert default value
                        else if (!instrumentationItem.amount) {
                            instrumentationItem.amount = 1
                        }
                        setCurrentInstrumentation(instrumentationItem)
                        props.onChange!(currentInstrumentation)
                    }}
                    value={currentInstrumentation.instrument as InstrumentDTO} />
                {isInstrumentButtonVisible && <Button type="primary" onClick={(_) => showModal()}>Nowy instrument</Button>}

            </div>
            <div className="instrItemAmount">
                {currentInstrumentation.instrument?.isCountable && <Input
                    type="number"
                    inputMode="numeric"
                    min={1}
                    value={props.value?.amount}
                    onChange={(e) => {
                        let instrumentationItem = currentInstrumentation
                        instrumentationItem.amount = parseInt(e.target.value)
                        setCurrentInstrumentation(instrumentationItem)
                        props.onChange!(currentInstrumentation)
                    }}></Input>}
            </div>
            <div className="instrItemDeleteButton">
                <div className="desktopRemoveButton">
                    <Button
                        onClick={props.onDeleteRow}
                    >Usuń</Button>
                </div>
            </div>
        </div>
        <div className="mobileRemoveButton">
            <Button
                size="small"
                type="ghost"
                onClick={props.onDeleteRow}
            >Usuń</Button></div>
        <Modal
            title="Dodaj instrument/zespół"
            visible={isInstrumentModalVisible}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        handleInstrumentAdd(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            onCancel={handleModalCancel}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{ modifier: 'public' }}
            >
                <Form.Item
                    name="name"
                    label="Nazwa"
                    rules={[{ required: true, message: 'Wprowadź nazwę' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="isCountable"
                    label="Ilość?"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox />
                </Form.Item>
            </Form>
        </Modal>
    </div>
}

export default InstrumentationItemField;