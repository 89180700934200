import { ComposerDTO, CreateComposerDTO, CreateOrUpdateCompositionDTO, CompositionDTO, InstrumentDTO, CreateInstrumentDTO, EmailDTO, PageResponseDTO, SearchRequestDTO } from './dto/DtoTypes'
import config from './config'

const baseUrl = config.apiBaseUrl

const searchComposers = (searchTerm: string): Promise<ComposerDTO[]> => {
    return fetchUsingGet<ComposerDTO[]>('/composer/search/' + searchTerm)
        .then(composers => composers.map(c => new ComposerDTO(c.surname, c.givenNames, c.id, c.yearBorn, c.yearDeceased)))
}

const getAllComposers = (): Promise<ComposerDTO[]> => {
    return fetchUsingGet<ComposerDTO[]>('/composer')
        .then(composers => composers.map(c => new ComposerDTO(c.surname, c.givenNames, c.id, c.yearBorn, c.yearDeceased)))
}

const compilePageParams = (page: number, size: number, sortOn: string, sortDir: string) => {
    return `page=${page}&size=${size}&sortOn=${sortOn}&sortDir=${sortDir}`
}

const getCompositionCount = () => {
    return fetchUsingGet<number>('/composition/count')
}

const getComposerPage = (page: number, size: number, sortOn: string, sortDir: string): Promise<PageResponseDTO<ComposerDTO>> => {
    return fetchUsingGet<PageResponseDTO<ComposerDTO>>('/composer/page?' + compilePageParams(page, size, sortOn, sortDir))
}

const searchInstrument = (searchTerm: string): Promise<InstrumentDTO[]> => {
    return fetchUsingGet<InstrumentDTO[]>('/instrument/search/' + searchTerm)
        .then(instruments => instruments
            .map(c => new InstrumentDTO(c.id, c.name, c.isCountable)))
}

const getInstrumentByExactName = (name: string): Promise<InstrumentDTO> => {
    return fetchUsingGet<InstrumentDTO>('/instrument/' + name)
        .then(c => new InstrumentDTO(c.id, c.name, c.isCountable))
}

const addInstrument = (instrument: CreateInstrumentDTO): Promise<InstrumentDTO> => {
    return sendPostAcceptJson("/instrument", instrument)
}

const sendEmail = (email: EmailDTO): Promise<any> => {
    return createUsingPostAcceptText("/email", email)
    .catch(e => console.log(e))
}

const searchCompositions = (searchTerms: string): Promise<CompositionDTO[]> => {
    return fetchUsingGet<CompositionDTO[]>('/composition/search/' + searchTerms)
        .then(compositions => compositions.map
            (c => {
                // FIXME find way to use type directly instead of this bullshit 

                const composer = c.composer ? new ComposerDTO(c.composer.surname,
                    c.composer.givenNames,
                    c.composer.id,
                    c.composer.yearBorn,
                    c.composer.yearDeceased)
                    : undefined

                return new CompositionDTO(
                    c.title,
                    c.subtitle,
                    c.id,
                    composer,
                    c.createdYears,
                    c.lyricsAuthor,
                    c.dedication,
                    c.parts,
                    c.premiere,
                    c.editionInfo,
                    c.compositionCompetitions,
                    c.recordings,
                    c.literature,
                    c.manuscript,
                    c.notes,
                    c.duration,
                    c.instrumentation,
                    c.isReviewed,
                    c.matchedFields)
            }))
}

const getCompositionsByComposerId = (composerId: number) => {
    return fetchUsingGet<CompositionDTO[]>('/composition?composerId=' + composerId)
}

const searchByAttributes = (search: SearchRequestDTO): Promise<CompositionDTO[]> => {
    return sendPostAcceptJson<SearchRequestDTO, CompositionDTO[]>('/composition/search/byAttributes', search)
}

const getAllCompositions = (): Promise<CompositionDTO[]> => {
    return fetchUsingGet<CompositionDTO[]>('/composition')
        .then(compositions => compositions.map
            (c => {
                // FIXME find way to use type directly instead of this bullshit 

                const composer = c.composer ? new ComposerDTO(c.composer.surname,
                    c.composer.givenNames,
                    c.composer.id,
                    c.composer.yearBorn,
                    c.composer.yearDeceased)
                    : undefined

                return new CompositionDTO(
                    c.title,
                    c.subtitle,
                    c.id,
                    composer,
                    c.createdYears,
                    c.lyricsAuthor,
                    c.dedication,
                    c.parts,
                    c.premiere,
                    c.editionInfo,
                    c.compositionCompetitions,
                    c.recordings,
                    c.literature,
                    c.manuscript,
                    c.notes,
                    c.duration,
                    c.instrumentation,
                    c.isReviewed)
            }))
}

const getComposition = async (compId: number): Promise<CompositionDTO> => {
    return fetchUsingGet<CompositionDTO>('/composition/' + compId)
}

const getComposer = async (compId: number): Promise<ComposerDTO> => {
    return fetchUsingGet<ComposerDTO>('/composer/' + compId)
}

const addComposer = (composer: CreateComposerDTO): Promise<ComposerDTO> => {
    return sendPostAcceptJson("/composer", composer)
}

const addComposition = (composition: CreateOrUpdateCompositionDTO): Promise<CompositionDTO> => {
    return sendPostAcceptJson("/composition", composition)
}

const updateComposition = (composition: CreateOrUpdateCompositionDTO, id: number): Promise<CompositionDTO> => {
    console.log("Sending composition update, id: " + id)
    return updateUsingPut("/composition", id, composition)
}

const updateComposer = (composer: CreateComposerDTO, id: number): Promise<ComposerDTO> => {
    return updateUsingPut("/composer", id, composer)
}

const deleteComposition = (compositionId: number): Promise<CompositionDTO> => {
    return deleteEntity("/composition", compositionId)
}

function deleteEntity<T>(endpoint: string, entityId: number): Promise<T> {
    return fetch(baseUrl + endpoint + "/" + entityId, {
        method: 'DELETE',
        mode: 'cors'
    })
        .then(res => res.json())
        .then(res => { return res as T })
}

function sendPostAcceptJson<T, U>(endpoint: String, payload: T): Promise<U> {
    return fetch(baseUrl + endpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(payload)
    })
        .then(res => res.json())
        .then(res => { return res as U })
}

function createUsingPostAcceptText<T>(endpoint: String, payload: T): Promise<Response> {
    return fetch(baseUrl + endpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/plain'
        },
        body: JSON.stringify(payload)
    })
}

function updateUsingPut<T, U>(endpoint: String, id: number, payload: T): Promise<U> {
    return fetch(baseUrl + endpoint + "/" + id, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        },
        body: JSON.stringify(payload)
    })
        .then(res => res.json())
        .then(res => { return res as U })
}

function fetchUsingGet<T>(endpoint: string): Promise<T> {
    return fetch(baseUrl + endpoint, {
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => res.json())
        .then(res => { return res as T })
}

export {
    searchComposers,
    addComposer,
    addComposition,
    searchCompositions,
    deleteComposition,
    getComposition,
    getComposer,
    updateComposition,
    searchInstrument,
    addInstrument,
    getInstrumentByExactName,
    getAllCompositions,
    sendEmail,
    getComposerPage, 
    getCompositionsByComposerId,
    searchByAttributes,
    getCompositionCount,
    getAllComposers,
    updateComposer
} 