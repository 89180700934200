import React, { useState, FC, useEffect } from 'react';
import { CompositionDTO } from './dto/DtoTypes';
import { searchCompositions, deleteComposition, getCompositionCount } from './api';
import { Input, List, Button } from 'antd';
import 'antd/dist/antd.css';
import { useRouteMatch, Link } from 'react-router-dom';
import debounce from 'lodash/debounce';

type SearchProps = {
    admin: boolean
}

const Search: FC<SearchProps> = (props: SearchProps) => {

    const [compositionCount, setCompositionCount] = useState<number>()

    useEffect(() => {
        getCompositionCount()
        .then(count => setCompositionCount(count))
    }, [])

    const fetch = (value: string, callback: (data: CompositionDTO[]) => void) => {
        function call() {
            searchCompositions(value)
                .then(compositions => callback(compositions))
                .catch(_ => setVisibleCompositions([]))
        }
        call()
    }

    let match = useRouteMatch();

    const [visibleCompositions, setVisibleCompositions] = useState<CompositionDTO[]>([])

    const fetchCompositions = debounce((searchTerm: string) => {
        if (searchTerm) {
            fetch(searchTerm, (data) => setVisibleCompositions(data))

        }
        else {
            setVisibleCompositions([])
        }
    }, 200)

    const removeComposition = (compositionId: number) => {
        deleteComposition(compositionId)
            .then(_ => setVisibleCompositions(visibleCompositions.filter(c => c.id !== compositionId)))
    }
    return <div className="search">
        <h1>nagitare.pl</h1>
        <h5>Internetowy katalog polskiej muzyki gitarowej XX i XXI wieku</h5>
        <Input
            style={{ width: '100%' }}
            size="large"
            onChange={e => { fetchCompositions(e.target.value) }}
            placeholder="Zacznij pisać (minimum 4 znaki)"
        />
        <List
            dataSource={visibleCompositions}
            locale={{ emptyText: " " }}
            renderItem={item =>
                <List.Item>
                    {props.admin
                        ? <Link to={`${match.path}/${item.id}`}>{item.title}</Link>
                        : <Link to={`/compositionDetails/${item.id}`}>{item.title}</Link>}
                    {item.createdYears
                        ? <span>&nbsp;({item.createdYears})&nbsp;</span>
                        : <span>&nbsp;</span>}

                    -&nbsp;{item.composer?.displayableName()}
                    &nbsp;<span className="matchedFields">znaleziono w: {item.matchedFields.join(", ")}</span>
                    {props.admin && <span>&nbsp;-&nbsp;</span>}
                    {props.admin && <Button
                        size="small"
                        onClick={_ => {
                            removeComposition(item.id)
                        }}>Usuń</Button>}
                </List.Item>
            }
        />
        {!props.admin && visibleCompositions.length === 0 &&
            <div>
                <p>
                    Witamy w Internetowym Katalogu Polskiej Muzyki Gitarowej XX i XXI, bazie danych o polskich kompozycjach na gitarę i z udziałem gitary klasycznej – od muzyki solowej, poprzez utwory kameralne, koncerty gitarowe i muzykę orkiestrową. Skorzystaj z powyższej wyszukiwarki ogólnej, aby znaleźć utwór wg kryteriów słownych. Wyszukiwanie zaawansowane pozwoli odnaleźć utwór wg instrumentacji. Jeśli zaś jakiegoś utworu nie ma w bazie danych – zapraszamy do skorzystania z formularza w zakładce "Zaproponuj utwór".
                </p>
                <div>Obecnie w bazie znajduje się {compositionCount} utworów.</div>
            </div>}
    </div>
}

export default Search