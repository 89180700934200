import React, { FC, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom";
import { getComposition } from "./api"
import { CompositionDTO, ComposerDTO, compareByPosition } from "./dto/DtoTypes";
import { Row, Col, Spin } from "antd";
import CompositionDetail from "./CompositionDetail";

const CompositionDetails: FC = () => {
    const [loading, setLoading] = useState(true)
    const [composition, setComposition] = useState<CompositionDTO>();
    let { compId } = useParams();
    const fetchComposition = async () => {
        getComposition(compId)
            .then(r => {
                const extractedComposer = r.composer
                    ? new ComposerDTO(
                        r.composer.surname,
                        r.composer.givenNames,
                        r.composer.id,
                        r.composer.yearBorn,
                        r.composer.yearDeceased)
                    : undefined;
                setComposition(new CompositionDTO(
                    r.title,
                    r.subtitle,
                    r.id,
                    extractedComposer,
                    r.createdYears,
                    r.lyricsAuthor,
                    r.dedication,
                    r.parts,
                    r.premiere,
                    r.editionInfo,
                    r.compositionCompetitions,
                    r.recordings,
                    r.literature,
                    r.manuscript,
                    r.notes,
                    r.duration,
                    r.instrumentation.sort(compareByPosition)
                ))
            })
            .then(_ => setLoading(false))
    }
    useEffect(() => {
        fetchComposition()
    }, []);

    if (loading) {
        return <Spin delay={75} />
    }
    const instrumentationText = instrumentationToString(composition)
    const createdYearsString = composition?.createdYears
        ? `(${composition?.createdYears})`
        : '';
    const composerYearsString = (composition?.composer?.yearBorn || composition?.composer?.yearDeceased)
        ? `(${composition?.composer?.yearBorn ? composition?.composer?.yearBorn : ""}-${composition?.composer?.yearDeceased ? composition?.composer?.yearDeceased : ""})`
        : '';
    const titleGroup = <div id="compositionTitle">
        <h1 style={{
            display: 'inline-block'
        }}>{composition?.title}</h1>
        &nbsp;
        <span style={{
            display: 'inline-block',
            fontSize: '13px'
        }}>
            {createdYearsString}
        </span>
        {composition?.subtitle &&
            <span style={{
                display: 'block',
                fontSize: '18px'
            }}>
                {composition?.subtitle}
            </span>}

    </div>;
    const composerGroup = <div id="composerDetails">
        <h3 style={{ display: 'inline-block' }}>
        <Link to={`/catalogue/composer/${composition?.composer?.id}`}>{composition?.composer?.displayableName()}</Link></h3>
        &nbsp;
        <span style={{
            display: 'inline-block',
            fontSize: '10px'
        }}>
            {composerYearsString}
        </span>
    </div>;
    const notes = <Row gutter={10} style={{ marginTop: 20, marginLeft: 0, marginRight: 0 }}>
        {composition?.notes && <Col xl={16} lg={24} md={24} sm={24} xs={24} style={{padding: 0}}>
            <div
                className="compositionDetailLabel"
                style={{
                    width: '10%',
                }}>Uwagi:&nbsp;&nbsp;</div>
            <div
                className="compositionDetailValue"
                style={{
                    width: '90%',
                }}>{composition?.notes}</div>
        </Col>}
    </Row>;
    const detailsInGrid = <Row>
        <Col xl={7} lg={24} md={24} sm={24} xs={24}>
            <CompositionDetail
                label="Obsada"
                value={instrumentationText} />
            <CompositionDetail
                label="Wydanie"
                value={composition?.editionInfo} />

            <CompositionDetail
                label="Części"
                value={composition?.parts} />
        </Col>
        <Col xl={7} lg={24} md={24} sm={24} xs={24}>
            <CompositionDetail
                label="Dedykacja"
                value={composition?.dedication} />
            <CompositionDetail
                label="Autor tekstu"
                value={composition?.lyricsAuthor} />
            <CompositionDetail
                label="Czas trwania"
                value={composition?.duration} />
        </Col>
        <Col xl={10} lg={24} md={24} sm={24} xs={24}>
            <CompositionDetail
                label="Prawykonanie"
                value={composition?.premiere} />
            <CompositionDetail
                label="Konkursy kompozytorskie"
                value={composition?.compositionCompetitions} />
            <CompositionDetail
                label="Literatura"
                value={composition?.literature} />
            <CompositionDetail
                label="Nagrania"
                value={composition?.recordings} />
            <CompositionDetail
                label="Rękopis"
                value={composition?.manuscript} />
        </Col>
    </Row>;
    return <div id="compositionDetails">
        {titleGroup}
        {composerGroup}
        {detailsInGrid}
        {notes}
    </div>
}
export default CompositionDetails

function instrumentationToString(composition: CompositionDTO | undefined) {
    return composition?.instrumentation && composition?.instrumentation.length > 0
        ? composition?.instrumentation
            .map((item) => {
                let maybeAmount: string = (item.instrument.isCountable) ? ` (${item.amount})` : "";
                return `${item.instrument.name}${maybeAmount}`;
            })
            .join(", ")
        : undefined;
}
