import React, { FC, useState } from 'react';
import { Input, Form, Button, Row, Col, Alert, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { sendEmail } from './api';

const formatBody = (store: Store) => {
    return `
Tytuł: ${store.title}
Podtytuł: ${store.subtitle}
Kompozytor: ${store.composer}
Lata powstania: ${store.createdYears}
Autor tekstu: ${store.lyricsAuthor}
Dedykacja: ${store.dedication}
Części: ${store.parts}
Czas trwania: ${store.duration}
Prawykonanie: ${store.premiere}
Informacje o wydaniu: ${store.editionInfo}
Konkursy kompozytorskie: ${store.compositionCompetitions}
Nagrania: ${store.recordings}
Literatura: ${store.literature}
Rękopis: ${store.manuscript}
Uwagi: ${store.notes}
Instrumentacja: ${store.instrumentation}
`
}

const ContactForm: FC = () => {
    const [errorWarningVisible, setErrorWarningVisible] = useState<boolean>(false)
    const [submittedSuccesfully, setSubmittedSuccesfully] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)

    const onFinish = (values: Store) => {
        setSubmitting(true)
        setErrorWarningVisible(false)
        setSubmittedSuccesfully(false)
        if (values.period) {
            return
        }
        else {
            sendEmail({ returnAddress: values.contactEmail, body: formatBody(values) })
                .then(res => {
                    let status = undefined
                    status = res.status
                    
                    if (status !== 200) {
                        setErrorWarningVisible(true)
                        setSubmitting(false)
                    }
                    else {
                        setSubmitting(false)
                        setSubmittedSuccesfully(true)
                    }
                })
                .catch(_ => {
                    setSubmitting(false)
                    setErrorWarningVisible(true)
                })
        }

    }

    return <div>
        <div style={{marginTop: '3%', marginBottom:'2%'}}>
        Jeśli w Internetowym Katalogu Polskiej Muzyki Gitarowej XX i XXI wieku brakuje jakiejś kompozycji, z chęcią uzupełnimy braki – zachęcamy do wypełnienia poniższego formularza. Również w przypadku błędnych lub niekompletnych danych dotyczących utworu już znajdującego się w bazie będziemy wdzięczni za sprostowanie/uzupełnienie przesłane za pomocą formularza.
        </div>
        <Form
            onFinish={onFinish}
            layout="horizontal"
            labelCol={{ span: 6 }}
        >
            <Row>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="title"
                        label="Tytuł"
                        rules={[{ required: true, message: 'Wprowadź tytuł' }]}
                    >
                        <Input
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item name="subtitle" label="Podtytuł">
                        <Input
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item name="period" style={{margin: 0, padding:0, height:0}}>
                        <Input style={{ display: "none", margin: 0, padding: 0 }} />
                    </Form.Item>

                    <Form.Item
                        name="composer"
                        label="Kompozytor"
                        rules={[{ required: true, message: 'Wprowadź kompozytora' }]}
                    >
                        <Input
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item name="createdYears" label="Lata powstania">
                        <Input
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="lyricsAuthor"
                        label="Autor tekstu"
                    >
                        <Input
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="dedication"
                        label="Dedykacja"
                    >
                        <Input
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="parts"
                        label="Części"
                    >
                        <Input.TextArea
                            rows={4}
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="duration"
                        label="Czas trwania"
                    >
                        <Input
                            style={{ width: '90%' }} />
                    </Form.Item>


                    <Form.Item
                        name="premiere"
                        label="Prawykonanie"
                    >
                        <Input.TextArea
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="editionInfo"
                        label="Informacje o wydaniu"
                    >
                        <Input.TextArea
                            rows={2}
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="compositionCompetitions"
                        label="Konkursy kompozytorskie"
                    >
                        <Input.TextArea
                            rows={2}
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="recordings"
                        label="Nagrania"
                    >
                        <Input.TextArea
                            rows={2}
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="literature"
                        label="Literatura"
                    >
                        <Input.TextArea
                            rows={2}
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="manuscript"
                        label="Rękopis"
                    >
                        <Input
                            style={{ width: '90%' }} />
                    </Form.Item>

                    <Form.Item
                        name="notes"
                        label="Uwagi"
                    >
                        <Input.TextArea
                            rows={2}
                            style={{ width: '90%' }} />
                    </Form.Item>
                    <Form.Item
                        name="contactEmail"
                        label="Adres email do kontaktu"
                        rules={[{ required: true, message: 'Podaj adres kontaktowy' }]}
                    >
                        <Input
                            type="email"
                            style={{ width: '90%' }} />
                    </Form.Item>
                </Col>
                <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="instrumentation"
                        label="Instrumentacja">
                        <Input.TextArea
                            rows={3}
                            style={{ width: '90%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={submitting}>
                    Wyślij</Button>
                &nbsp;&nbsp;
                {submitting && <Spin size="small"/>}
            </Form.Item>

            {submittedSuccesfully && <Alert
                message="Utwór wysłany!"
                type="success" />}

            {errorWarningVisible && <Alert
                message="Coś poszło nie tak, spróbuj ponownie za parę minut"
                type="error"
                closable={true}
                onClose={() => setErrorWarningVisible(false)} />}
        </Form>
    </div>
}

export default ContactForm;