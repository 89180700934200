import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { InstrumentationDTO } from "../dto/DtoTypes";

function countDuplicates(arr: InstrumentationDTO[]) {
    return [...arr
        .map(item => item.instrument.id)
        .reduce((acc, current) => {
            let count = acc.get(current);
            if (count === undefined) {
                acc.set(current, 1);
            }
            else {
                acc.set(current, count + 1);
            }
            return acc;
        },
            new Map<number, number>()).values()]
        .filter(ocurrence => ocurrence > 1)
        .length;
}

function validator(_: RuleObject, value: StoreValue) {
    const arr = (value as InstrumentationDTO[]);
    if (arr.length === 0) {
        return Promise.reject("Wprowadź przynajmniej jedną pozycję!")
    }
    if (arr.filter(i => i.instrument?.id === undefined).length > 0) {
        return Promise.reject("Usuń pozycje bez wybranego składu!")
    }

    if (arr.filter(i => i.instrument.isCountable && !i.amount).length > 0) {
        return Promise.reject("Uzupełnij liczbę dla wszystkch instrumentów")
    }
    const duplicates = countDuplicates(arr)

    if (duplicates > 0) {
        return Promise.reject("Usuń zduplikowane instrumenty!")
    }
    return Promise.resolve()
}

export {validator}