import React, { FC } from "react"
import { ComposerDTO, CompositionDTO } from "./dto/DtoTypes";
import { v4 as uuid } from 'uuid'
import { Link, Redirect, useLocation } from "react-router-dom";

type AdvancedSearchResultState = {
    compositions: CompositionDTO[]
}


const AdvancedSearchResult: FC = () => {
    const location = useLocation<AdvancedSearchResultState>()

    if (!location.state.compositions) {
        return <Redirect to="/advanced" />
    }

    return <div>
            <h1>Wyszukiwanie zaawansowane -&nbsp;wyniki</h1>
                {location.state.compositions?.length > 0 ? <ul 
                id="advancedSearchResultsList"
                style={{ listStyleType: 'none' }}>
                    {location.state.compositions?.map(c =>
                        <li key={uuid()} style={{ marginTop: 5, marginBottom: 5 }}>
                            <span style={{
                                    display: 'inline-block',
                                    fontSize: '18px'
                                }}>{new ComposerDTO(c.composer?.surname, c.composer?.givenNames).displayableName()}
                                &nbsp;-&nbsp;
                            </span>
                            <Link to={`/compositionDetails/${c.id}`} style={{ fontSize: 18 }}>{c.title}
                            </Link>
                            {c.subtitle &&
                                <span style={{
                                    display: 'inline-block',
                                    fontSize: '15px'
                                }}>&nbsp;{c.subtitle}
                                </span>}

                            {c.createdYears &&
                                <span style={{
                                    display: 'inline-block',
                                    fontSize: '13px'
                                }}>&nbsp;({c.createdYears})
                            </span>}
                    </li>)}
                </ul>
                : <p style={{padding: 10}}>Nie znaleziono utworów odpowiadających podanym kryteriom.</p>}
        </div>
}

export default AdvancedSearchResult