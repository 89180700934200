import React, { FC, useState } from 'react';



const About: FC = () => {
    
    

    return <div>
        <h1>O projekcie</h1>
        <p>
            Internetowy katalog polskiej muzyki gitarowej XX i XXI wieku to projekt mający na celu zebranie i upowszechnienie informacji o polskich kompozycjach z udziałem gitary klasycznej powstałych w XX i XXI wieku. Katalog, udostępniony w formie strony internetowej z rozbudowaną wyszukiwarką, umożliwia swobodny dostęp do możliwie pełnych informacji o polskich utworach, w których użyta jest gitara klasyczna: od muzyki solowej, poprzez kompozycje kameralne (zarówno te jednorodne, jak i o mieszanej obsadzie), koncerty na gitarę i orkiestrę, aż do utworów orkiestrowych, w których gitara wpleciona jest w tkankę orkiestry. 
            Na każdy rekord opisujący utwór składa się, w miarę dostępu do danych, szereg informacji: tytuł oraz podtytuł utworu, data powstania, autor (wraz z latami życia), obsada, autor tekstu (w przypadku utworów wokalno-instrumentalnych), dedykacja, nazwy części, czas trwania, informacje o prawykonaniu, wydaniu, udziale utworu w konkursach kompozytorskich, nagraniach, literaturze przedmiotu oraz o miejscu przechowywania rękopisu; dodatkowe informacje znajdują się w sekcji Uwagi. Dzięki zaawansowanym opcjom wyszukiwania możliwe jest znalezienie dzieł wg dowolnego tekstu, a także poprzez lata powstania oraz użyte instrumentarium, co jest szczególnie przydatne w kontekście kompozycji kameralnych.
            Niniejszy katalog tworzony jest od 2009 roku i od tego czasu udało się zgromadzić informacje o blisko 2000 kompozycjach z udziałem gitary klasycznej. Jednak utwory te dalej powstają – stąd podstrona Zaproponuj utwór, na której poprzez formularz można przesłać informacje o nieobecnej jeszcze w katalogu kompozycji, bądź też uzupełnić lub sprostować dane o już zarejestrowanym w bazie utworze. W zakładce Katalog dostępne zaś są wszystkie zgromadzone rekordy z podziałem wg kompozytora.
            Katalog zrealizowano w ramach programu stypendialnego Ministra Kultury i Dziedzictwa Narodowego – Kultura w sieci. 

        </p>
        <p>
        Autorem merytorycznej części Internetowego katalogu polskiej muzyki gitarowej XX i XXI wieku jest Wojciech Gurgul – doktorant Uniwersytetu Humanistyczno-Przyrodniczego im. Jana Długosza w Częstochowie. Jest absolwentem Ogólnokształcącej Szkoły Muzycznej I i II st. im. Feliksa Nowowiejskiego w Gdańsku w klasie gitary Joanny Rudnickiej oraz Akademii Muzycznej im. Karola Szymanowskiego w Katowicach w klasie gitary prof. Aliny Gruszki, ukończył także Weekendowy Kurs Edytorstwa Muzycznego oraz studia podyplomowych w zakresie administracji i zarządzania w oświacie. W latach 2014–2017 był redaktorem naczelnym kwartalnika miłośników gitary klasycznej „Sześć Strun Świata”. Jest dwukrotnym stypendystą programu „Białe plamy – muzyka i taniec”, autorem książki „30 lat Śląskiej Jesieni Gitarowej”, redaktorem szeregu wydawnictw nutowych; jego artykuły ukazywały się w czasopismach „Edukacja Muzyczna” oraz „Kwartalnik Młodych Muzykologów UJ”. Pracuje jako pedagog w PSM I i II st. im. M. Karłowicza w Mielcu oraz SM I st. w Bobowej. 
        </p>
        <p>
        Za część techniczną odpowiada Daniel Czosnek – absolwent Akademii Muzycznej im. Karola Szymanowskiego w Katowicach w klasie gitary prof. Wandy Palacz - z zamiłowania (i wykonywanego zawodu) programista.
        </p>
        <p>
        Projekt powstał we współpracy z Ośrodkiem Dokumentacji Historycznej Gitarystyki Polskiej, który powstał w 2019 roku z inicjatywy Wojciecha Gurgula. Celem Ośrodka jest gromadzenie, pozyskiwanie i opracowywanie wiedzy historycznej dotyczącej dziejów gitary i innych instrumentów strunowych szarpanych w Polsce i na dawnych ziemiach polskich oraz dokumentowanie i upowszechnianie wiedzy związanej z historią gitarystyki polskiej – w tym zabezpieczenie zgromadzonych materiałów archiwalnych.
        </p>
        
    </div>
}

export default About;