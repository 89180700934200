import { Button, Form, Spin } from "antd"
import { Store } from "antd/lib/form/interface";
import React, { FC, useEffect, useState } from "react"
import { getInstrumentByExactName, searchByAttributes } from "./api";
import { ComposerDTO, CompositionDTO, InstrumentationDTO, SearchRequestDTO } from "./dto/DtoTypes";
import InstrumentationField from "./InstrumentationField";
import { Redirect } from "react-router-dom";
import { validator } from "./util/InstrumentationFieldValidator";

const AdvancedSearch: FC = () => {
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)

    const [isSubmitted, setSubmitted] = useState(false)
    const [instrumentation, setInstrumentation] = useState<InstrumentationDTO[]>([])
    const [searchResult, setSearchResult] = useState<CompositionDTO[]>([])

    const fetchGuitar = () => {
        if (instrumentation.length === 0) {
            getInstrumentByExactName("Gitara")
                .then(guitar => setInstrumentation([{ instrument: guitar, amount: 1, position: 1 } as InstrumentationDTO]))
                .then(_ => setLoading(false))
                .catch(e => console.log(e))
        }
    }
    useEffect(fetchGuitar, [])
    const convert = (values: Store) => {
        return { instrumentation: values.instrumentation } as SearchRequestDTO
    }
    const onFinish = (values: Store) => {
        setSubmitting(true)
        const payload = convert(values)
        searchByAttributes(payload)
            .then(result => {
                const compositions = result.map(c => {
                    const composer = new ComposerDTO(
                        c.composer?.surname,
                        c.composer?.givenNames,
                        c.composer?.id,
                        c.composer?.yearBorn,
                        c.composer?.yearDeceased)
                    return new CompositionDTO(
                        c.title,
                        c.subtitle,
                        c.id,
                        composer,
                        c.createdYears,
                        c.lyricsAuthor,
                        c.dedication // FIXME deal with this shit already, this is going to cause problems
                    )
                })
                setSearchResult(compositions)
            })
            .catch(e => console.log(e))
            .finally(() => {
                setSubmitted(true)
                setSubmitting(false)
            })
    }
    if (isSubmitted) {
        return <Redirect push to={{
            pathname: "/advanced/result",
            state: {
                compositions: searchResult
            }
        }} />
    }

    if (loading) {
        return <Spin delay={75} />
    }

    return <div>
        <h1>Wyszukiwanie zaawansowane</h1>
        <Form
            onFinish={onFinish}

            initialValues={{
                instrumentation: instrumentation
            }
            }>
            <Form.Item
                name="instrumentation"
                label="Instrumentacja"
                rules={[{
                    validator: validator
                }]}
            >
                <InstrumentationField
                    admin={false}
                    value={instrumentation}
                    onChange={(c) => setInstrumentation([...c])} />
            </Form.Item>
            <Form.Item style={{ width: "100%" }}>
                <Button type="primary"
                    id="advancedSearchButton"
                    htmlType="submit" disabled={submitting}>
                    Szukaj
                </Button>
                &nbsp;
                &nbsp;
  {submitting && <Spin size="small" />}

            </Form.Item>
        </Form>
    </div>
}

export default AdvancedSearch