import Search from "./Search"
import React, { FC, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdvancedSearch from "./AdvancedSearch";
import AdvancedSearchResult from "./AdvancedSearchResult";
import Catalogue from "./Catalogue";
import ComposerDetails from "./ComposerDetails";
import CompositionDetails from "./CompositionDetails";
import ContactForm from "./ContactForm";
import About from "./About"
import TopBar from "./TopBar";
import baner2 from './images/baner2.png'
import { v4 as uuid } from "uuid";

const Content: FC = () => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const toggleOpen = () => setMenuOpen(!menuOpen)
    const closeMenu = () => setMenuOpen(false);
    return <Router>
        <div className="wrapper">
            <TopBar toggleOpen={toggleOpen} open={menuOpen} close={closeMenu} />
            <div className="userScreen" onClick={closeMenu}>
                <Switch>
                    <Route path="/compositionDetails/:compId" component={CompositionDetails} />
                    <Route path="/advanced/result" component={AdvancedSearchResult} />

                    <Route path="/advanced" component={AdvancedSearch} />

                    <Route path="/contact" component={ContactForm} />
                    <Route path="/about" component={About} />
                    <Route path="/catalogue/composer/:composerId" component={ComposerDetails} />
                    <Route path="/catalogue" exact render={(_) => <Catalogue key={uuid()} />} />
                    <Route path="/" exact render={(_) => <Search admin={false} />} />
                </Switch>

            </div>

            <footer className="bottomBar">
            <div className="authors">
                    <div className="wrap">
                        <div>&copy;&nbsp;2020-{new Date().getFullYear()} </div>
                        <div>opracowanie katalogu: Wojciech Gurgul</div>
                        <div>projekt strony: Daniel Czosnek</div>
                    </div>
                </div>
                <div id="banner"><img src={baner2} alt="Zrealizowano w ramach programu stypendialnego MKiDN - Kultura w sieci" />
                </div>
            </footer>
        </div>
    </Router>
}

export default Content