import React, { FC } from 'react'
import { Row } from 'antd'

type CompositionDetailProps = {
    label: string
    value?: string
}

const CompositionDetail: FC<CompositionDetailProps> = (props: CompositionDetailProps) => {
    return props.value ? <Row gutter={10} style={{ marginTop: 10, marginLeft: 0, marginRight: 0 }} className="compositionDetail">
         <div style={{ width: '100%' }}>
                <div 
                className="compositionDetailLabel">{props.label}:&nbsp;&nbsp;</div>
                <div 
                className="compositionDetailValue">{props.value}</div>
            </div>
    </Row>
    : <div></div>
}

export default CompositionDetail