import React, { FC, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom";
import { getComposer, getCompositionsByComposerId } from "./api"
import { CompositionDTO, ComposerDTO } from "./dto/DtoTypes";
import { Spin } from "antd";
import { v4 as uuid } from 'uuid'


const ComposerDetails: FC = () => {
    const [loading, setLoading] = useState(true)
    const [compositions, setCompositions] = useState<CompositionDTO[]>();
    const [composer, setComposer] = useState<ComposerDTO>()
    let { composerId } = useParams();
    const fetchCompositionsByComposer = async () => {
        getComposer(composerId)
            .then(c => setComposer(new ComposerDTO(c.surname, c.givenNames, c.id, c.yearBorn, c.yearDeceased)))
            .then(async _ => {
                const res = await getCompositionsByComposerId(composerId);
                return res.map(r => {
                    return new CompositionDTO(
                        r.title,
                        r.subtitle,
                        r.id,
                        composer,
                        r.createdYears,
                        r.lyricsAuthor,
                        r.dedication,
                        r.parts,
                        r.premiere,
                        r.editionInfo,
                        r.compositionCompetitions,
                        r.recordings,
                        r.literature,
                        r.manuscript,
                        r.notes,
                        r.duration,
                        r.instrumentation
                    );
                });
            })
            .then(comps => setCompositions(comps))
            .then(_ => setLoading(false))
            .catch(e => console.log(e))

    }
    useEffect(() => {
        fetchCompositionsByComposer()
    }, []);

    if (loading) {
        return <Spin delay={75} />
    }

    const composerYearsString = (composer?.yearBorn || composer?.yearDeceased)
        ? `(${composer?.yearBorn ? composer?.yearBorn : ""}-${composer?.yearDeceased ? composer?.yearDeceased : ""})`
        : '';
    return <div id="composerDetails">
        <div id="composerFullName" >
            <h1 style={{ display: 'inline-block', marginBottom: 20 }}>{composer?.displayableName()}</h1>
            &nbsp;
            <span style={{
                display: 'inline-block',
                fontSize: '13px'
            }}>
                {composerYearsString}
            </span>
            <ul style={{ listStyleType: 'none' }}>
                {compositions?.map(c =>
                    <li key={uuid()} style={{ marginTop: 5, marginBottom: 5 }}>
                        <Link to={`/compositionDetails/${c.id}`} style={{ fontSize: 18 }}>{c.displayableName()}
                        </Link>
                        {c.subtitle &&
                            <span style={{
                                display: 'inline-block',
                                fontSize: '15px'
                            }}>&nbsp;{c.subtitle}
                            </span>}

                        {c.createdYears &&
                            <span style={{
                                display: 'inline-block',
                                fontSize: '13px'
                            }}>&nbsp;({c.createdYears})
                            </span>}
                    </li>)}

            </ul>
        </div>
    </div>
}
export default ComposerDetails

