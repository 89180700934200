import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import { Displayable } from './dto/DtoTypes';
import { v4 as uuid } from 'uuid';

type SearchWithNotFoundFunctionProps<T extends Displayable> = {
  notFoundFunction: (value: string) => void;
  value?: T;
  onChange?: (value: T | undefined) => void;
  initialValues: T[];
  fetchingFuntion: (value: string, callback: (data: T[]) => void) => void;
}

const { Option } = Select;

const SearchWithNotFoundFunction = <U extends Displayable,>(props: SearchWithNotFoundFunctionProps<U>) => {
  const triggerChange = (changedValue: U | undefined) => {
    if (props.onChange) {
      props.onChange(changedValue);
    }
  };

  const [selectedValue, setSelectedValues] = useState<U | undefined>(props.value);
  const [foundValues, setFoundValues] = useState<U[]>(props.initialValues);
  const options = foundValues.map(d => {
    const displayableName = d.displayableName();
    return <Option
      key={uuid()}
      value={d.displayableName()}>
      {displayableName}
    </Option>
  });
  const handleSearch = (value: string) => {
    if (value) {
      props.fetchingFuntion(value, data => setFoundValues(data));
    } else {
      setFoundValues(props.initialValues);
    }
    if (foundValues.length === 0) {
      props.notFoundFunction(value)
    }
  }
  const handleChange = (val: string) => {
    setSelectedValues(foundValues.find(v => v.displayableName() === val))
    triggerChange(foundValues.find(v => v.displayableName() === val));
  }

return <div>
    <Select
      showSearch
      value={selectedValue ? selectedValue.displayableName() : ""}
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      filterOption={false}
      defaultActiveFirstOption={false}
      notFoundContent={null}
    >
      {options}
    </Select>
  </div>
}

export default SearchWithNotFoundFunction;
