import React, { FC, useState, useEffect } from 'react';
import { Button } from 'antd';
import { InstrumentationDTO, InstrumentDTO } from './dto/DtoTypes';
import { searchInstrument } from './api';
import InstrumentationItemField from './InstrumentationItemField';
import { v4 as uuid } from 'uuid';
import './InstrumentationField.css'
import { debounce } from 'lodash';

type InstrumentationFieldProps = {
    value?: InstrumentationDTO[];
    onChange?: (value: InstrumentationDTO[]) => void;
    admin: boolean
}

function fetch(value: string, callback: (data: InstrumentDTO[]) => void) {
    function call() {
        searchInstrument(value)
            .then(result => callback(result))
    }
    call()
}

const InstrumentationField: FC<InstrumentationFieldProps> = (props: InstrumentationFieldProps) => {
    const [instrumentationItems, setInstrumentationItems] = useState<InstrumentationDTO[]>(props.value!)
    useEffect(() => {
        setInstrumentationItems(props.value!)
    }, [props.value])
    const fetchInstruments = debounce((value: string, callback: (data: InstrumentDTO[]) => void) => {
        fetch(value, callback)
    })

    const addNewItem = () => {
        let newValue = [...instrumentationItems];
        newValue.push({} as InstrumentationDTO);
        props.onChange!([...newValue]);
    };
    return <div id="instrFieldContainer">
        {props.value!.map((v, index) =>
            <div className="instrField" key={uuid()}>
                <InstrumentationItemField
                    admin={props.admin}
                    onChange={(changed) => {
                        let newValue = [...instrumentationItems]
                        newValue[index] = {
                            position: index,
                            amount: changed.amount,
                            instrument: changed.instrument
                        } as InstrumentationDTO
                        props.onChange!([...newValue])
                    }}
                    value={v}
                    onDeleteRow={() => {
                        let newValue = [...instrumentationItems]
                            .filter((_, indexToRemove) => indexToRemove !== index)
                        props.onChange!([...newValue])
                    }}
                    fetchInstruments={fetchInstruments}
                />
            </div>
        )}
        <Button
            id="instrumentationAddButtonMobile"
            type="ghost"
            size="small"
            onClick={addNewItem}>Dodaj pozycję</Button>

        <Button
            id="instrumentationAddButtonDesktop"
            onClick={addNewItem}>Dodaj pozycję</Button>

    </div>
}

export default InstrumentationField;


